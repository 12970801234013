import React from 'react'
import './App.css';
import Home from "./components/pages/Home";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import About from "./components/pages/About";// Import the JSON data
import Contact from './components/pages/Contact';
import LayoutMain from './components/LayoutMain';
import Referral from './Referral';
import logo from './Referral/assets/beemi.svg'
import ReferalHome from './Referral';


const SimpleLayout = ({ children }) => {
  return (
    <div className="h-[100vh]">
      <header className="w-full h-16 sm:h-24 flex justify-center items-center " >
        <img className="h-14 sm:h-24 -ml-6" src={logo} alt="logo" />
      </header>
      <ReferalHome />
    </div>
  );
}


function App() {

  return (
    <BrowserRouter>
      <Routes>
        <Route element={<LayoutMain />}>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/contact" element={<Contact />} />
        </Route>
        <Route element={<SimpleLayout />}>
          {/* <Route path="/referral" element={<Referral />} /> */}
          <Route path="/:username" element={<Referral />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
