import React from 'react';

export default function OPS() {
  return (
    <div className='flex flex-col justify-center items-center pt-10'>
        <h1 className='text-[#9A9A9A] font-bold text-4xl'>oops..</h1>
        <h2 className='text-[#9A9A9A] font-[700] text-2xl md:max-w-[25%] max-w-[60%] text-center mt-5'>
        This Number is Already registered for a discount
        </h2>
    </div>
  );
}
