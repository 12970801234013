import React, { useEffect, useState, useRef } from 'react';
import Layout from '../Layout';
import Header from '../Header';
import Footer from '../Footer';
import { Outlet } from 'react-router-dom';

export default function LayoutMain() {
    
    const [prevScrollPos, setPrevScrollPos] = useState(window.pageYOffset);
    const ticking = useRef(false); // Use useRef to avoid unnecessary re-renders

    useEffect(() => {
        const handleScroll = () => {
            const currentScrollPos = window.pageYOffset || window.scrollY;

            if (!ticking.current) {
                window.requestAnimationFrame(() => {
                    if (prevScrollPos > currentScrollPos) {
                        document.getElementById("header").style.top = "0px"; // Show navbar when scrolling up
                    } else {
                        document.getElementById("header").style.top = "-100px"; // Hide navbar when scrolling down
                    }
                    setPrevScrollPos(currentScrollPos);
                    ticking.current = false;
                });
                ticking.current = true;
            }
        };

        window.addEventListener("scroll", handleScroll);

        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, [prevScrollPos]);

    return (
        <Layout>
            <Header />
            <Outlet/>
            <Footer />
        </Layout>
    );
}
