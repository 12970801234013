import React, { useEffect, useState } from 'react';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import back from '../../images/back.svg';
import { useNavigate } from 'react-router-dom';

const responsive = {
    superLargeDesktop: {
        // the naming can be any, depends on you.
        breakpoint: { max: 4000, min: 3000 },
        items: 5
    },
    desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 4
    },
    tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 2
    },
    mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1
    }
};

const ButtonGroup = ({ next, previous, goToSlide, ...rest }) => {
    const { carouselState: { currentSlide } } = rest;
    return (
        <div >
            <button className="absolute top-1/2 transform -translate-y-1/2 left-0 md:left-[15%] p-5" onClick={() => previous()}> <img src={back} className='w-12 h-12' /></button>
            <button className="absolute top-1/2 transform -translate-y-1/2 right-0 md:right-[15%] p-5" onClick={() => next()}> <img src={back} className='w-12 h-12 rotate-180' /></button>
        </div>
    );
};

const CenterElement = () => {
    const navigate = useNavigate();

    const items = React.useRef(
        [
            {
                username: '@muaz_jemal',
                image: 'https://p16-sign-va.tiktokcdn.com/tos-maliva-avt-0068/bdc5f7dad859fbae61c39cd237a79203~c5_1080x1080.jpeg?lk3s=a5d48078&nonce=37691&refresh_token=ab02dfe4e3e918e1cdb6ac417626ab12&x-expires=1737190800&x-signature=XVR2%2BfLdHHiLxqPGDulENux%2Fkls%3D&shp=a5d48078&shcp=81f88b70'
            },
            {
                username: '@kbra_a_million',
                image: 'https://p16-sign-va.tiktokcdn.com/tos-maliva-avt-0068/c8c30a3dec675e9a17880f77b86f7ca2~c5_1080x1080.jpeg?lk3s=a5d48078&nonce=7370&refresh_token=a1cee68c07164b4aff28dda3f5699eb1&x-expires=1737190800&x-signature=yJWkQKg2sEcTy7G%2F%2BjeWtPX8YMs%3D&shp=a5d48078&shcp=81f88b70'
            },
            {
                username: '@4kilo_entertainment',
                image: 'https://p16-sign-va.tiktokcdn.com/tos-maliva-avt-0068/8f45140aaeae6b9be9caa25161d300b5~c5_1080x1080.jpeg?lk3s=a5d48078&nonce=60472&refresh_token=b1ddc9b3d14dce3ceadd831568b58845&x-expires=1737190800&x-signature=XeCcGg3qJ47%2B6tW6sWa5QZUrKFo%3D&shp=a5d48078&shcp=81f88b70'
            },
            {
                username: '@minte.digital',
                image: 'https://p16-sign-va.tiktokcdn.com/tos-maliva-avt-0068/7329091876861149189~c5_1080x1080.jpeg?lk3s=a5d48078&nonce=38302&refresh_token=fa1d74eecf608d2352a3dbba7244bcbb&x-expires=1737190800&x-signature=YxX5RGHET3BO5dKoBcSzqx%2BH2Kc%3D&shp=a5d48078&shcp=81f88b70'
            },
            {
                username: '@official_ebbat',
                image: 'https://p16-sign-va.tiktokcdn.com/tos-maliva-avt-0068/a616d96fc9ae91f166b205d321dfc538~c5_1080x1080.jpeg?lk3s=a5d48078&nonce=22005&refresh_token=0bc13541429a9a42b6c08181fb856244&x-expires=1737190800&x-signature=YZVedsiGC%2F%2BX2HV9tmwlOrv1q5o%3D&shp=a5d48078&shcp=81f88b70'
            }
        ]
    );
    const navigateToNextPage = (username, photo) => {
        if (photo) {
            let newUser = username.replace('@', '');
            const url = `/${newUser}`;
            navigate(url, { state: { photo } });
        } else {
            const url = `/${username}`.replace('@', '');
            navigate(url);
        }
    };
    useEffect(() => {
        const pathSegments = window.location.pathname.split('/');
        const username = pathSegments[pathSegments.length - 1];
        if (username) {
            console.log(`Username from URL: ${username}`);
        }
    }, []);

    return (
        <div className=" w-[100vw] md:h-[60vh] h-[70vh] bg-gradient-to-r from-yellow-500 to-[#F2419C] py-0 relative flex flex-col justify-center items-center overflow-hidden pl-[20vw] pr-[20vw]">
            <h1 className='text-2xl text-white font-bold md:mt-10 md:-mb-10'>Get a 20% Discount Through Your Favorite Influencers</h1>
            <Carousel
                swipeable={true}
                draggable={true}
                showDots={false}
                responsive={responsive}
                autoPlaySpeed={1000}
                keyBoardControl={true}
                arrows={false}
                customTransition="all .5"
                transitionDuration={500}
                containerClass="w-[100%] h-[70vh]"
                customButtonGroup={<ButtonGroup />}
                renderButtonGroupOutside={true}
                dotListClass="md:!p-10 text-white absolute"
                itemClass=" w-[100%] h-[70vh] flex flex-row justify-center items-center"
            >
                {items.current.map((item, index) => (
                    <div key={index} className="bg-white p-4 rounded-lg shadow-md shadow-black m-2 min-w-[94%]  h-[35vh] flex flex-col items-center">
                        <img src={item.image} alt={`Avatar ${index}`} className="w-32 h-32 rounded-full mb-4 border-[5px] border-transparent bg-gradient-to-r from-[#F56967]  to-pink-500 p-[3px]"
                        />
                        <p className="text-lg max-w-[80%] font-extrabold bg-clip-text text-transparent bg-gradient-to-r from-yellow-500 to-[#F2419C]">{item.username}</p>
                        <button onClick={() => navigateToNextPage(item.username, item.image)} className="mt-auto w-[100%]  bg-gradient-to-r from-yellow-500 to-[#F2419C] rounded-full text-white py-2 px-4 shadow-md shadow-black font-[Montserrat-Bold]">Get 20% Off</button>
                    </div>
                ))}
            </Carousel>
        </div>
    );
}

export default CenterElement;