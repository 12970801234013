import React from 'react';

export default function Great() {
    return (
        <div className='flex flex-col justify-center items-center pt-10'>
            <h1 className='text-[#F76A68] font-bold text-4xl'>Great Job!</h1>
            <h2 className='text-[#F76A68] mt-5 font-[700] text-2xl md:max-w-[25%] max-w-[60%] text-center'>
                we will get in touch with you soon
            </h2>
        </div>
    );
}
